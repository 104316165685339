























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop({ type: Number, default: 1 })
  step!: number
}

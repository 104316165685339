


















import { Component, Vue } from 'vue-property-decorator'
import VABox from 'va/widgets/VABox.vue'
import StepBar from '@/components/organisms/Purchase/Contract/Change/StepBar.vue'

@Component({ components: { VABox, StepBar } })
export default class extends Vue {
  move(): void {
    this.$router.push({
      name: 'Contract',
    })
  }
}
